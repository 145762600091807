import React from 'react'
import InfoIcon from 'react-feather/dist/icons/info'

import Link from './Link'
import './ContactCTA.css'

const ContactCTA = () => {
  return (
    <div className="ContactCTA">
      <InfoIcon /> <span>Get in touch with Sam via the</span>
      <Link to="/contact/">Contact Page</Link>
    </div>
  )
}

export default ContactCTA
