import React from 'react'

import Image from './Image'
import './Gallery.css'

export default ({ images = [], title, ...props }) => (
  <div className="Gallery">
    {images
      .filter((galleryItem) => galleryItem && galleryItem.publicURL)
      .map((galleryItem, index) => (
        <div key={galleryItem.publicURL + index} className="Gallery--Item">
          <Image src={galleryItem} alt={title} />
        </div>
      ))}
  </div>
)
