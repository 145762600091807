import React from 'react'
import { graphql } from 'gatsby'
import _get from 'lodash/get'

import Layout from '../components/Layout'
import Header from '../components/Header'
import Hero from '../components/Hero'
import Content from '../components/Content'
import SEO from '../components/Seo'
import Gallery from '../components/Gallery'
import ContactCTA from '../components/ContactCTA'
import './Page.css'

const Page = ({
  data: {
    page: {
      html,
      frontmatter: {
        title,
        subtitle,
        featured_image: featuredImage,
        gallery = [],
      },
    },
  },
}) => (
  <Layout>
    <SEO title={title} description={subtitle} keywords={[]} />
    <Header inverted />
    <Hero
      className="Home--Hero"
      title={title}
      subtitle={subtitle}
      image={featuredImage}
    />

    <section className="section Page--MainContent" id="page-main-content">
      <div className="container skinnier">
        <Content src={html} />
        <ContactCTA />
      </div>
    </section>

    {gallery && !!gallery.length && (
      <section className="section thin">
        <Gallery images={gallery} title={title} />
      </section>
    )}
  </Layout>
)

export const pageQuery = graphql`
  query PageBySlug($slug: String!) {
    page: markdownRemark(fields: { slug: { eq: $slug } }) {
      id
      html
      frontmatter {
        title
        subtitle
        featured_image {
          ...LargeImage
        }
        gallery {
          ...TracedImageMediumSquare
        }
      }
    }
  }
`

export default Page
